import SubscriptionService from '@/services/subscription.service'
import { useMutation } from '@tanstack/vue-query'
import { NotificationStore } from '@/store/notification.store'

export const useAddTopUp = () => {
    const { $subscriptionService, $notificationStore } = useNuxtApp()
    const subscriptionService = $subscriptionService as SubscriptionService
    const notificationStore = $notificationStore as NotificationStore
    return useMutation({
        mutationFn: subscriptionService.addTopUp,
        onSuccess: (data) => {
            if (data.url) {
                window.open(data.url, '_self')
            } else {
                console.error('Top up URL is missing')
            }
        },
        onError: () => {
            notificationStore.notifyError({
                summary: 'Error Adding Top up',
                detail: '',
                life: 3000,
            })
        },
    })
}
