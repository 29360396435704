<script lang="ts" setup>
import { AppAbility } from '@/utils/ability'
import { useAbility } from '@casl/vue'

defineProps<{
    showModal: boolean
}>()

const [topupDialogVisible, toggleTopUpDialog] = useToggle(false)
const { can } = useAbility<AppAbility>()

const emit = defineEmits<{
    'update:display': [display: boolean]
}>()

const closeModal = () => {
    emit('update:display', false)
}

const updateHandler = () => {
    closeModal()
    navigateTo('/pricing')
}

const switchDialogs = () => {
    toggleTopUpDialog()
    closeModal()
}
</script>
<template>
    <Dialog
        :visible="showModal"
        :closeOnEscape="true"
        modal
        header="Exhausted credits"
        position="top"
        :draggable="false"
        @update:visible="closeModal"
        :closable="true"
        :style="{ width: '500px' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="flex flex-column justify-content-center">
            <div class="flex justify-content-center mb-4">
                <i class="pi pi-info-circle text-6xl text-orange-500"></i>
            </div>
            <template v-if="can('manage', 'Subscription')">
                <p class="text-center text-lg">
                    Your credits are low. Please purchase more credits or
                    upgrade to continue.
                </p>
                <div class="flex justify-content-center gap-3">
                    <a
                        @click.prevent="updateHandler"
                        class="flex justify-content-center"
                        href="/pricing"
                    >
                        <Button
                            size="small"
                            class="text-base px-2 py-2"
                            label="Upgrade"
                            type="button"
                        />
                    </a>
                    <Button
                        class="text-base px-2 py-2"
                        size="small"
                        label="Top up Credits"
                        @click="switchDialogs()"
                        type="button"
                    />
                </div>
            </template>
            <template v-else>
                <p class="text-center text-lg">
                    Your credits for this month have been utilised. Please ask
                    your admin to increase your credits.
                </p>
            </template>
        </div>
    </Dialog>
    <TopupTopUpDailog
        :showModal="topupDialogVisible"
        @update:display="toggleTopUpDialog"
    />
</template>
