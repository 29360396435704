<script lang="ts" setup>
import { object, number } from 'zod'
import { useForm, useField } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { useAddTopUp } from '@/composables/subscription'

defineProps<{
    showModal: boolean
}>()

const emit = defineEmits<{
    'update:display': [display: boolean]
}>()

const { mutateAsync, isPending } = useAddTopUp()

const topupFormSchema = toTypedSchema(
    object({
        amount: number({ invalid_type_error: 'Please enter a number' }).min(1),
    })
)

const { handleSubmit } = useForm({
    validationSchema: topupFormSchema,
    initialValues: {
        amount: 0,
    },
})

const { value: amount, errorMessage: amountError } = useField<number>('amount')

const updateDisplay = (value: boolean) => {
    emit('update:display', value)
}

const closeModal = () => {
    updateDisplay(false)
}

const onSubmit = handleSubmit(async (values) => {
    await mutateAsync(values.amount)
    closeModal()
})
</script>

<template>
    <Dialog
        :visible="showModal"
        :closeOnEscape="true"
        modal
        header="Top Up"
        position="top"
        :draggable="false"
        @update:visible="closeModal"
        :closable="true"
        :style="{ width: '29rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <form @submit.prevent="onSubmit">
            <div class="flex flex-column gap-2">
                <p class="text-base text-gray-600">
                    Low on credits? Top up now to continue enjoying our
                    services!
                </p>
                <div class="flex flex-column gap-1">
                    <label for="amount" class="text-sm">Topup Amount</label>
                    <InputNumber
                        v-model="amount"
                        id="amount"
                        class="w-full"
                        :class="{
                            'p-invalid': amountError,
                        }"
                        aria-describedby="amount-error"
                        :maxFractionDigits="2"
                    />
                    <small
                        v-if="amountError"
                        class="errMessage p-error"
                        id="amount"
                        >{{ amountError }}</small
                    >
                </div>

                <Button
                    :loading="isPending"
                    type="submit"
                    class="w-fit ml-auto mt-2"
                    size="small"
                >
                    <template #default>
                        <i
                            v-if="isPending"
                            class="pi pi-spinner pi-spin mr-2"
                        ></i>
                        Add
                    </template>
                </Button>
            </div>
        </form>
    </Dialog>
</template>
